footer.bg-dark-footer {
  background-color: #1d1e25 !important;
}

.services .card {
  min-height: 370px;
}

.process .card {
  min-height: 340.45px;
}

.jobs .card {
  min-height: 322.45px;
}

.career {
  text-align: center;
  margin-top: 200px;
  margin-bottom: 200px !important;
  padding-bottom: 100px !important;
}

.m-100 {
  margin: 100px 0;
}

.file-control {
  height: 100% !important;
  padding: 15px 10px !important;
}

@media screen and (max-width: 768px) {
  .m-100 {
    margin: 50px 0;
  }
}
