.brand-logo-wrapper {
  width: 115px;
}
.contact-us-button {
  background-color: #be1e2d !important;
  outline: #be1e2d !important;
  border: #be1e2d !important;
  height: 45px !important;
  font-size: 15px !important;
}
.btn-primary {
  
}
.navbar-custom1 {
  position: fixed !important;
  background-color: #fff;
}
.navbar-custom1 .nav-link{
  color: #1D1E25;
}

.navbar-brand-custom {
  width: 115px !important;
}

.custom-close-button {
  background-color: #1e2228 !important;
  border: 0;
  color: white;
}

@media screen and (max-width: 992px) {
  .contact-us-button {
    margin-top: 20px;
  }
}
