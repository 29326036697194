.grid {
  column-count: 4;
  column-gap: 20px;
  /* row-gap: 20px; */
  max-width: 1600px;
  margin: auto;
  width: 100%;
}

.grid img {
  margin-top: 20px;
  height: auto;
  max-width: 100%;
  border-radius: 12px;
}

@media (max-width: 800px) {
  .grid {
    column-count: 3;
  }
}

@media (max-width: 600px) {
  .grid {
    column-count: 2;
  }
}

@media (max-width: 400px) {
  .grid {
    column-count: 1;
  }
}

.grid-wrapper {
  margin: 100px 15px;
}
