.get-in-touch {
  text-align: center;
  margin-top: 200px;
  margin-bottom: 200px !important;
  padding-bottom: 100px !important;
}

.contactForm .invalid-feedback {
  display: block;
}
.contact-above-section {
  padding-right: 0 !important;
}
.pr-0 {
  padding-right: 0 !important;
}
.mr-0 {
  margin-right: 0 !important;
}
