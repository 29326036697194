.hero-section {
  align-items: center;
  background-color: #f5f5f5;
  /* background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABWSURBVHgB7c5RDYBADIPhBgVIwBkacAAOUHjDASi4sUvAQJ//L1nWt1YCAACmiFhba3ddjCzTJFNm7vXmuqXyKZM94Cv/PTLZA3rvW71rlFc+BAAATC9CRh3xkuYppwAAAABJRU5ErkJggg==); */
  background-size: 16px;
  color: #121212;
  display: flex;
  height: 620px;
  justify-content: center;
}
